
import { defineComponent } from 'vue';

declare const google: any;

export default defineComponent({
	name: 'WeatherAlert',
	components: {
	},
	mounted() {
		google.accounts.id.initialize({
			client_id: "275149879632-n22u390a4g4b775oedora75g9voih2is.apps.googleusercontent.com",
			callback: this.handleCredentialResponse
		});

		google.accounts.id.renderButton(
			document.getElementById("google-login"), {
			scope: "email",
			theme: "dark",
			size: "medium"
		}  // customization attributes
		);
		//google.accounts.id.prompt(); // also display the One Tap dialog
	},
	methods: {
		handleCredentialResponse(response: any) {
			console.log('response', response);
		},
		googleLogin() {
			console.log('google login');
		}
	}
});
